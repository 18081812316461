// Copyright 2021 andreygorbov
// 
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
// 
//     http://www.apache.org/licenses/LICENSE-2.0
// 
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
import axios from 'axios'
import { API_URL} from '../var'

export async function fetchCourse({ idc }) {
   return await axios.get(API_URL+'/courses', {
    params: {
        idc
      }
    }).then(res => {
        return res.data
    }).catch(err => console.log(err))
  }
  
  
export async function getLessons({ idc, courseId }) {
   return await axios.get(API_URL+'/lessons', {
      params: {
        idc,
        courseId
      }
    }).then(res => {
      return res.data
    }).catch(err => console.log(err))
}

/* ---------------------------- admin section ---------------------------- */
  
export async function addСourse(data) {
    return await axios.post(API_URL+'/courses', {
       ...data
    }).then(res => {
       return res.data
     }).catch(err => console.log(err)).finally(async () => {
      await axios.get(API_URL+'/update')
    })
}

export async function updateStatusCourse(data) {
 
  return await axios.post(API_URL+'/courses', {...data}
  ).then(res => {
     return res.data
  }).catch(err => console.log(err)).finally(async () => {
    await axios.get(API_URL+'/update')
  })
  
}
   
export async function updateСourse(data) {

    return await axios.post(API_URL+'/courses', {
       ...data,
       }).then(res => {
       return res.data.lessons
     }).catch(err => console.log(err)).finally(async () => {
      await axios.get(API_URL+'/update')
    })
}
   
export async function addLesson({ idc, courseId }) {
    return await axios.post(API_URL+'/lessons', {
       data: {
        idc,
        courseId,
        action: "add"
       }
     }).then(res => {
       return res.data.lessons
     }).catch(err => console.log(err))
}

export async function updateLesson({ idc, lessonId }) {
    return await axios.post(API_URL+'/lessons', {
       data: {
        idc,
        lessonId,
        action: "update"
       }
     }).then(res => {
       return res.data.lessons
     }).catch(err => console.log(err))
}
export async function delLesson({ idc, lessonId }) {
  return await axios.post(API_URL+'/lessons', {
      idc,
      lessonId,
      action: "del"
  }).then(res => {

   }).catch(err => console.log(err))
}