// Copyright 2021 andreygorbov
// 
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
// 
//     http://www.apache.org/licenses/LICENSE-2.0
// 
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React, {useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';


const Wrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
`
const P = styled.p`
color: #ECB869;
font-size: 7vw;
    margin: 0;
    text-align: center;
`
const Z = styled.p`
color: #ECB869;
font-size: 30vw;
    margin: 0;
`
export const NoPage = () => {
    const history = useHistory()
    
useEffect(() => {
    setTimeout(() => {
        history.push('/')
      }, 6000)    
}, [history])


   return  (
        <Wrapper>
            <Z>404</Z>
           <P>Такой страницы не существует</P>
        
        </Wrapper>
   
    )
}
