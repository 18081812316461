

import React from 'react';
import styled from 'styled-components'
import { Toggle} from '../components/ui/toggle'


const Body = styled.div`
display: flex;
flex-direction: column;
flex-grow:1;
`

const Screen1 = styled.div`
position: relative;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: 100vh;
flex-grow:1;
background-repeat: no-repeat;
background-position: center;
background-size: cover;
background-image: url(${({ img }) => img && img});

`
const Overley = styled.div`
display: flex;
background-color: rgba(0,0,0,0.4);
flex-grow:1;
width:100%;
justify-content: center;
align-items: center;
`
const Screen2 = styled.div`
margin: 0 0 2rem 0;
display: flex;
justify-content: center;
align-items: center;
flex-grow:1;
flex-wrap:wrap;
box-sizing: border-box;
`


const Sect = styled.div`
width: 60%;
display: flex;
flex-direction: column;
align-items: left;
box-sizing: border-box;
`
const Image = styled.div`
height:550px;
min-width: ${({ big }) => big ? "100%" : "450px"};
background-attachment: fixed;
background-repeat: no-repeat;
background-position: ${({ revers }) => revers===true ? 'left' : 'right'};
background-size: ${({big})=> big?"cover":"contain"};
background-image: url(${({img})=>  img});
box-sizing: border-box;
background-position-x:${({ revers }) => revers?"10%":"90%"};
@media screen and (max-width: 1000px) {
    //width:100%;
    background-position: center;
    background-position-x:${({ revers }) => revers?"5%":"95%"};
  }
  @media screen and (max-height: 400px){
    background-attachment: scroll;
    background-position-x: center;
    background-position: center;
  }
  @media screen and (max-width: 450px) {
    min-width: 100%;
    background-position: center;
  }
  @media screen and (max-width: 800px) {
    background-position-x: center;
    background-attachment: fixed;
  }
  @media  (max-width: 1100px) {
    background-position-x: ${({ revers }) => revers===true ? 'left' : 'right'};
  }
`
const Dobro = styled.div`
position:relative;
padding: 0 0 4rem 0;
margin: 0 3rem;
display: flex;
min-width:50%;
flex-direction: column;
box-sizing: border-box;
height: fit-content;
line-height: 1.4;
text-align:  ${({ revers }) => revers ? 'right' : 'left'};
align-items: ${({ revers }) => revers ? 'flex-end' : 'flex-start'};
@media  (max-width: 1100px) {
margin: 0;
margin: 0 1rem;
min-width: unset;
  }
`
const Gradient = styled.div`
position: absolute;
display: flex;
bottom: 0px;
    width: 100%;
height: 10vh;
left: 0px;
background: linear-gradient(${({theme})=> theme.gradient});

`
const Heder = styled.p`
width:${({revers})=> revers && "min-content"};
white-space: pre-line;
font-family: 'Ubuntu';
font-style: normal;
font-weight: 800;
font-size: 1em;
letter-spacing: 6px;
text-transform: uppercase;
color: ${({theme, color})=> color?color:theme.heder};
@media screen and (max-width: 1000px) {
    font-size: 0.8em;
  }
`

const P = styled.p`
white-space: pre-line;
font-family: 'Ubuntu';
font-style: normal;
font-weight: normal;
font-size: 1.1em;
line-height: 32px;
color: ${({theme})=> theme.text};
@media screen and (max-width: 320px){
  font-size: ${({size}) =>size?size:"0.8em"};
}
@media screen and (max-width: 1000px){
  font-size: ${({size}) =>size?size:"1em"};
}
`

const H1 = styled.p`
white-space: pre-line;
margin: 0;
font-family: 'Lora';
font-style: normal;
font-weight: 600;
font-size: 3em;
text-transform: uppercase;
color: ${({theme, color})=> color?color:theme.text};
@media screen and (max-width: 1000px) {
    font-size: 1.7em;
  }
@media screen and (max-width: 768px) {
    font-size: 1.5em;
  }
`
const H2Line = styled.div`
background-color: ${({theme, color})=> color?color:theme.heder};
height: 2px;
width: 30px;
margin: 0 1em 0 0;
`
const DivG = styled.div`
display: flex;
align-items: center;
`
const Block = styled.div`
width: 80%;
display:flex;
align-items:center;
margin-bottom: 2rem;
flex-direction: ${({ revers }) => revers ? 'row-reverse' : 'row'};
@media screen and (max-width: 800px) {
flex-direction: column;
align-items: center;
justify-content: center;
padding: 0 20px;
  }
@media screen and (max-width: 1200px) {
  width: 100%;
}
`
const Button = styled.div`
width: 100%;
text-align: center;
align-items: center;
box-sizing: border-box;
justify-content: center;
display: flex;
margin: 4rem auto;
cursor: pointer;
padding: 11px 20px;
//border: solid 1px #fff;
&:hover{
  background-color: ${({theme})=> theme.button.hover};
}
`

const Screen4 = styled.div`
height: 100vh;
display:flex;
justify-content: center;
align-items: center;
`
const blocks = [ {
    bigheder:'Лучше всего начать с Знакомства',
    name: 'С чего начать?',
  text:`
Друзья, мы полностью переехали в Telegram. Во-первых, потому что он перекрывает потребности в Zoom и видеочатах, 
он анонимен насколько это возможно сегодня, не глючит и помогает в управлении контентом для групповых тренингов. 
У нас там даже можно таро и диагностику ауры делать.


В нашем "Чате ответов на вопросы" вы можете, 
слушать семинары в разделе  "музыка", задавать вопросы. Ссылка внизу страницы.

Помимо чата у нас есть бот  @terramagic_bot с помощью которого каждый подписчик может выиграть диагностику, записаться на курсы или пройти консультацию.
` 
   ,img:'',
    },
    {
        bigheder:'Обучение космоэнергетике',
        name: 'Уже решили, что хотите учиться Космоэнергетике у нас?',
        text: `
Надеюсь Ваше намерение в освоении тонких техник серьезно и непоколебимо. Тогда поговорим о том как начать заниматься с нами.
Рекомендую прослушать семинары в разделе "Музыка" нашего чата: 
"Вводная лекция по Космоэнергетике", "Процесс обучения в Космоэергетике",  "Дистанционные сонастройки"
Если это сделать многие вопросы у Вас исчезнут. 

Обучение проходит по следующему плану: 

Шаг 1.
Запись на собеседование. Там мы знакомимся, вы расскажете о себе и причинах, которые привели в нашу школу, мы ответим на ваши вопросы.  Задача собеседования решить обоюдно подходит ли наша школа Вам и подходите ли Вы нашей школе. Запись  через сообщение @funnyezo

Шаг 2.
Очные сонастройки в частоты Фарун-Будда, Фираст, Шаон, Зевс, Миди, Гектас. 
Пройти их можно в Москве, Германии или Латвии.

Шаг 3. 
Вы получаете доступ к онлайн части и участвуете в онлайн-занятиях раз в неделю с инструктором. 

Доступ к онлайн-курсу у вас бессрочный, поэтому вы всегда сможете пройти заново уроки, уточнить информацию и освежить знания.
Онлайн-занятия рассчитаны на 2 месяца. На них мы проходим темы, отрабатываем техники, разбираем вопросы. 
Хотим дополнительно отметить, что даже после завершения обучения инструктор будет с вами на связи и вы сможете оперативно получить ответы на возникшие вопросы.

По итогу обучения вы освоите базовые навыки космоэнергета: диагностику энергетического состояния,  инструменты быстрого восстановления и очищения ауры, научитесь работать с пространством и противодействовать внешним воздействиям.  Еще вы узнаете многое о током мире, энергетическом строении человека и т.д.
 
Вы сможете стать более энергичным и осознанным человеком, а полученные знания и умения будут однозначно полезны в любых жизненных ситуациях и сферах.

Оплату курса можно разбивать на несколько частей по договоренности. 

        `,
        img:'',
    },{
        bigheder:'Вы можете легко начать заниматься с нами',
        name: 'Я из другой школы',
      text: `
Есть варианты на выбор:
1. Принять участие в одной из экспедиций. Там мы обсудим отличия в программах и вы сможете выработать единую с нами понятийную базу.
2. Записаться на "Базовое обучение Космоэнергетике" и, собственно, освоите все основы в нашей школе, тем самым став ее полноценным участником. Вы можете проходить сонастройки по желанию. Они в любом случае включены в стоимость.

        `,
        img:'',
  }, {
    bigheder:'Большинство наших практик доступны и не космоэнергетам',
      name:'А можно попасть на другие занятия?',
      text: `
Я бы рекомендовал начать с освоения практик "Медитации" на курсе "Обучение медитации с нуля до продвинутого уровня"
В целом, вы можете принять участие почти во всех мероприятиях школы. Если вы хотите поехать в экспедицию, узнать о других сонастройках то организационные вопросы задавайте Сергею @funnyezo
      `,
      img:'',
      
  }, {
    bigheder:'Много всего',
      name:'Что после базового обучения?',
text: `
Можно продолжить заниматься космоэнергетикой и стать мастером, магистром, освоить космоэнергетический зороастризм, огненный цветок, заниматься можно на регулярных занятиях или индивидуально.
Ну или просто путешествовать с нами на места силы и осваивать смежные практики такие как Медитация, Рэйки, Ведическая астрология и тд.
`,
      img:'',
      
  }]


const H2 = ({children, color}) => (
  <DivG><H2Line color={ color}/> <Heder color={ color} bold='400'>{children}</Heder></DivG>
)

const InfoBlock = ({ img, heder, text, bigheder, revers }) => {
  const bigImage = heder!=="" || bigheder!=="" || text!==""

  return (
<Screen2> <Block revers={revers}>
      {bigImage && <Dobro revers={revers}>
        {heder && <H2 revers={revers}>{heder}</H2>}
        {bigheder && <H1>{bigheder}</H1>}
        {text && <P>{text}</P>}
      </Dobro>}
        
      {img && <Image big={!bigImage} img={img} revers={revers} />}
  </Block>
  </Screen2>
  )
}
  


export const Programs = ({setSelectedTheme}) => {

  const blocklist = blocks.map((block, key) => {
    const even = n => !(n % 2);
    const side = even(key)
    return (
    <InfoBlock
      key={Date.now()+`${key}`}
      revers={!side}
      heder={block.name}
      text={block.text}
      bigheder={block.bigheder}
      img={block.img}
    />
    )})
    return (
        <Body>
        <Toggle id={`${Date.now()}`}  setSelectedTheme={setSelectedTheme} color="#fff"/>
        <Screen1 img={'./images/fran.jpeg'}>
        <Gradient />
          <Overley>
                
                
                <Sect>
             
              <H1 color="#fff"margin="2rem 0 2rem 0">О ЗАНЯТИЯХ В ШКОЛЕ</H1>
                    
                </Sect>

            </Overley>
            </Screen1>
            
           
        {blocklist}
      

        

            <a href='https://t.me/terramagic/'>
            <Screen4>
          <Button>
            <svg xmlns="http://www.w3.org/2000/svg"
              width="60px"
              height="60px"
              viewBox="0 0 240 240">
      <defs>
        <linearGradient id="a" x1="0.667" x2="0.417" y1="0.167" y2="0.75">
          <stop offset="0" stopColor="#37aee2"></stop>
          <stop offset="1" stopColor="#1e96c8"></stop>
        </linearGradient>
        <linearGradient id="b" x1="0.66" x2="0.851" y1="0.437" y2="0.802">
          <stop offset="0" stopColor="#eff7fc"></stop>
          <stop offset="1" stopColor="#fff"></stop>
        </linearGradient>
      </defs>
      <circle cx="120" cy="120" r="120" fill="url(#a)"></circle>
      <path
        fill="#c8daea"
        d="M98 175c-3.888 0-3.227-1.468-4.568-5.17L82 132.207 170 80"
      ></path>
      <path
        fill="#a9c9dd"
        d="M98 175c3 0 4.325-1.372 6-3l16-15.558-19.958-12.035"
      ></path>
      <path
        fill="url(#b)"
        d="M100.04 144.41l48.36 35.729c5.519 3.045 9.501 1.468 10.876-5.123l19.685-92.763c2.015-8.08-3.08-11.746-8.36-9.349l-115.59 44.571c-7.89 3.165-7.843 7.567-1.438 9.528l29.663 9.259 68.673-43.325c3.242-1.966 6.218-.91 3.776 1.258"
      ></path>
    </svg>

            <P style={{marginLeft: "20px"}} size="1em">Чат лекций и ответов на вопросы:</P>
</Button>
</Screen4></a>

        
        </Body>  
)
}