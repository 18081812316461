// Copyright 2021 andreygorbov
// 
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
// 
//     http://www.apache.org/licenses/LICENSE-2.0
// 
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React, {createContext, useReducer} from "react";
import Reducer from './reducer'


const initialState = {
    courses: [],
    lessons: [],
    selector: "course",
    lessonId: 0,
    select: NaN,
    newlesson: false,
    courseIndex: 0,
    loadedAudio: 0,
    loadedImg:0,
    lessonData: {
        action: 'add',
        video: "",
        text: null,
        name: "",
        header: "",
        image: "",
        audio: '',
        queue: 0
    }
};


const Store = ({ children }) => {
    
    const [state, dispatch] = useReducer(Reducer, initialState);

    return (
        <StoreContext.Provider value={[state, dispatch]}>
            {children}
        </StoreContext.Provider>
    )
};

export const StoreContext = createContext(initialState);
export default Store;