

// Copyright 2021 andreygorbov
// 
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
// 
//     http://www.apache.org/licenses/LICENSE-2.0
// 
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React from 'react';
import styled from 'styled-components'
import { Video } from "../components/video"
import { Toggle} from '../components/ui/toggle'


const Body = styled.div`
display: flex;
flex-direction: column;
flex-grow:1;
`

const Screen1 = styled.div`
position: relative;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: 100vh;
flex-grow:1;
background-repeat: no-repeat;
background-position: center;
background-size: cover;
background-image: url(${({ img }) => img && img});

`
const Overley = styled.div`
display: flex;
background-color: rgba(0,0,0,0.4);
flex-grow:1;
width:100%;
justify-content: center;
align-items: center;
`
const Screen2 = styled.div`
margin: 0 0 2rem 0;
display: flex;
justify-content: center;
align-items: center;
flex-grow:1;
flex-wrap:wrap;
box-sizing: border-box;
`
const Screen3 = styled.div`
margin: 0 0 4rem 0;
display: flex;
justify-content: center;
align-items: center;
box-sizing: border-box;
div {
    height: inherit;
    margin: 0;
    position: relative;
    box-sizing: border-box;
    justify-content: center;
    display: flex;
    width: 100%;
  }
  
`

const Sect = styled.div`
width: 60%;
display: flex;
flex-direction: column;
align-items: left;
box-sizing: border-box;
`
const Image = styled.div`
height:550px;
min-width: ${({ big }) => big ? "100%" : "450px"};
background-attachment: fixed;
background-repeat: no-repeat;
background-position: ${({ revers }) => revers===true ? 'left' : 'right'};
background-size: ${({big})=> big?"cover":"contain"};
background-image: url(${({img})=>  img});
box-sizing: border-box;
background-position-x:${({ revers }) => revers?"10%":"90%"};
@media screen and (max-width: 1000px) {
    //width:100%;
    background-position: center;
    background-position-x:${({ revers }) => revers?"5%":"95%"};
  }
  @media screen and (max-height: 400px){
    background-attachment: scroll;
    background-position-x: center;
    background-position: center;
  }
  @media screen and (max-width: 450px) {
    min-width: 100%;
    background-position: center;
  }
  @media screen and (max-width: 800px) {
    background-position-x: center;
    background-attachment: fixed;
  }
  @media  (max-width: 1100px) {
    background-position-x: ${({ revers }) => revers===true ? 'left' : 'right'};
  }
`
const Dobro = styled.div`
position:relative;
padding: 0 0 4rem 0;
margin: 0 3rem;
display: flex;
min-width:50%;
flex-direction: column;
box-sizing: border-box;
height: fit-content;
line-height: 1.4;
text-align:  ${({ revers }) => revers ? 'right' : 'left'};
align-items: ${({ revers }) => revers ? 'flex-end' : 'flex-start'};
@media  (max-width: 1100px) {
margin: 0;
margin: 0 1rem;
min-width: unset;
  }
`
const Gradient = styled.div`
position: absolute;
display: flex;
bottom: 0px;
    width: 100%;
height: 10vh;
left: 0px;
background: linear-gradient(${({theme})=> theme.gradient});

`
const Heder = styled.p`
width:${({revers})=> revers && "min-content"};
white-space: pre-line;
font-family: 'Ubuntu';
font-style: normal;
font-weight: 800;
font-size: 1em;
letter-spacing: 6px;
text-transform: uppercase;
color: ${({theme, color})=> color?color:theme.heder};
@media screen and (max-width: 1000px) {
    font-size: 0.8em;
  }
`

const P = styled.p`
white-space: pre-line;
font-family: 'Ubuntu';
font-style: normal;
font-weight: normal;
font-size: 1.1em;
line-height: 32px;
color: ${({theme})=> theme.text};
@media screen and (max-width: 320px){
  font-size: ${({size}) =>size?size:"0.8em"};
}
@media screen and (max-width: 1000px){
  font-size: ${({size}) =>size?size:"1em"};
}
`

const H1 = styled.p`
white-space: pre-line;
margin: 0;
font-family: 'Lora';
font-style: normal;
font-weight: 600;
font-size: 3em;
text-transform: uppercase;
color: ${({theme, color})=> color?color:theme.text};
@media screen and (max-width: 1000px) {
    font-size: 1.7em;
  }
@media screen and (max-width: 768px) {
    font-size: 1.5em;
  }
`
const H2Line = styled.div`
background-color: ${({theme, color})=> color?color:theme.heder};
height: 2px;
width: 30px;
margin: 0 1em 0 0;
`
const DivG = styled.div`
display: flex;
align-items: center;
`
const Block = styled.div`
width: 80%;
display:flex;
align-items:center;
margin-bottom: 2rem;
flex-direction: ${({ revers }) => revers ? 'row-reverse' : 'row'};
@media screen and (max-width: 800px) {
flex-direction: column;
align-items: center;
justify-content: center;
padding: 0 20px;
  }
@media screen and (max-width: 1200px) {
  width: 100%;
}
`
const Button = styled.div`
width: 100%;
text-align: center;
align-items: center;
box-sizing: border-box;
justify-content: center;
display: flex;
margin: 4rem auto;
cursor: pointer;
padding: 11px 20px;
//border: solid 1px #fff;
&:hover{
  background-color: ${({theme})=> theme.button.hover};
}
`

const Screen4 = styled.div`
height: 100vh;
display:flex;
justify-content: center;
align-items: center;
`
const blocks = [ {
bigheder:'',
name: 'О Андрее',
text: `
Привет! Меня зовут Андрей Горбов. Собственно большую часть того, что вы здесь читаете пишу я.

Занимаюсь эзотерическими практиками и организацией сакральных путешествий уже более 20 лет. 
Накопленным опытом делюсь с теми кому это кажется полезным.

Когда-то был создателем форума "Путь сердца", на тот момент самой большой площадкой общения космоэнергетов.

Любимой практикой как можно догадаться, является Космоэнергетика учился которой у основателя метода В.А. Петрова. 
Считаю ее самой мощной и простой системой из всего, что видел, а побывал я в многих течениях как древних так и ньюэйдж.

Если захотите узнать больше просто приходите в мой телеграм бот ссылка внизу страницы. В нем бесплатные медитации, линки на чаты для общения и многое другое.
`,
img:'./images/andrey.jpeg',
},
{
bigheder:'Наш мастер Ведической Астрологии',
name: 'О Алене',
text: `
- Более 10 лет практики
- Ученица в парампаре Санджая Ратх, а так же изучала астрологию у других мастеров Индии и Непала.
- Более 1500 консультаций по всему миру
- Проводит консультации на Немецком, Английском и Русском языках
- Автор трех книг на тему саморазвития
- Целитель (в текущий момент прием не ведется)
- Практик медитации
- Магистр космоэнергетики школы Андрея Горбова        
`,
img:'./images/alena.jpg',
},{
bigheder:'Наш главный инструктор',
name: 'О Сергее',
text: `Сергей практикует в школе с 2012 года. Помог в трансформации курса начального обучения. 
В результате он стал понятнее и эффективнее в освоении.
Сейчас Сергей ведет многие курсы в нашей школе, среди которых "Начальное обучение Космоэнергетике В России".
Помимо прочего Сергей автор веселых эзотерических комиксов и несильно скрывается под псевдонимом
@Funnyezo в инстаграм.  
`,
img:'./images/sergey.jpg',
},{
name:'О нас',
bigheder: 'Кратко о наших взглядах',
text: `
Природа, пространство, космос - самый главный учитель в нашем мире. Именно прислушиваясь к его советам можно открыть в себе способность видеть тонкое, обрести новые грани реальности и открыть в себе новые силы. 
Чтобы лучше слышать природу и самих себя мы путешествуем на места силы по всему миру и через медитацию работаем над осознанностью, расширением воспринимаемого, достижением целостности. 
Мы нейтральны к религиям, как учил меня В.А. Петров.  Мы просто будем стремиться слышать то, что говорит нам мир и сердце.
ps Еще мы противники "Веществ силы" какие бы "натуральные" они бы не были. Все состояния достигаются только своими силами без допинга. 
`,
img:'',

  }]

const infobot = 
  {
    name:'Что полезного могу получить?',
    bigheder: 'В нашем боте вы найдете',
    text: `
  
  - Бесплатные медитации
  - Бесплатные сеансы
  - Участие в розыгрыше энергетической диагностики
  - Чаты для общения и ответов на вопросы
  - Огромный объем аудио лекций по КЭ и не только 
  - Описание предстоящих сакральных путешествий.
    `,
    img:'',
    
    }
  


const H2 = ({children, color}) => (
  <DivG><H2Line color={ color}/> <Heder color={ color} bold='400'>{children}</Heder></DivG>
)

const InfoBlock = ({ img, heder, text, bigheder, revers }) => {
  const bigImage = heder!=="" || bigheder!=="" || text!==""

  return (
<Screen2> <Block revers={revers}>
      {bigImage && <Dobro revers={revers}>
        {heder && <H2 revers={revers}>{heder}</H2>}
        {bigheder && <H1>{bigheder}</H1>}
        {text && <P>{text}</P>}
      </Dobro>}
        
      {img && <Image big={!bigImage} img={img} revers={revers} />}
  </Block>
  </Screen2>
  )
}
  


export const Oshkole = ({setSelectedTheme}) => {

  const blocklist = blocks.map((block, key) => {
    const even = n => !(n % 2);
    const side = even(key)
    return (
    <InfoBlock
      key={Date.now()+`${key}`}
      revers={side}
      heder={block.name}
      text={block.text}
      bigheder={block.bigheder}
      img={block.img}
    />
    )})
    return (
        <Body>
        <Toggle id={`${Date.now()}`}  setSelectedTheme={setSelectedTheme} color="#fff"/>
        <Screen1 img={'./images/oshkole.jpg'}>
        <Gradient />
          <Overley>
                
                
                <Sect>
              <H2 color="#FBD784">ШКОЛА АНДРЕЯ ГОРБОВА</H2>
              <H1 color="#fff"margin="2rem 0 2rem 0">Погружаем в тонкий мир</H1>
                    
                </Sect>

            </Overley>
            </Screen1>
            
           
        {blocklist}

        { <Screen3>
          <Video video='SnKy5J0n2Ak' height="500px" width="100%" />
        </Screen3>}
        <Screen2>
          <Block revers={false}>
      <Dobro revers={false}>
        
        <H2 revers={false}>{infobot.name}</H2>
 <H1>{infobot.bigheder}</H1>
 <P>{infobot.text}</P>
      </Dobro>
        
  </Block>
  </Screen2>
            <a href='https://t.me/terramag_bot?start=sait_about/'>
            <Screen4>
          <Button>
            <svg xmlns="http://www.w3.org/2000/svg"
              width="60px"
              height="60px"
              viewBox="0 0 240 240">
      <defs>
        <linearGradient id="a" x1="0.667" x2="0.417" y1="0.167" y2="0.75">
          <stop offset="0" stopColor="#37aee2"></stop>
          <stop offset="1" stopColor="#1e96c8"></stop>
        </linearGradient>
        <linearGradient id="b" x1="0.66" x2="0.851" y1="0.437" y2="0.802">
          <stop offset="0" stopColor="#eff7fc"></stop>
          <stop offset="1" stopColor="#fff"></stop>
        </linearGradient>
      </defs>
      <circle cx="120" cy="120" r="120" fill="url(#a)"></circle>
      <path
        fill="#c8daea"
        d="M98 175c-3.888 0-3.227-1.468-4.568-5.17L82 132.207 170 80"
      ></path>
      <path
        fill="#a9c9dd"
        d="M98 175c3 0 4.325-1.372 6-3l16-15.558-19.958-12.035"
      ></path>
      <path
        fill="url(#b)"
        d="M100.04 144.41l48.36 35.729c5.519 3.045 9.501 1.468 10.876-5.123l19.685-92.763c2.015-8.08-3.08-11.746-8.36-9.349l-115.59 44.571c-7.89 3.165-7.843 7.567-1.438 9.528l29.663 9.259 68.673-43.325c3.242-1.966 6.218-.91 3.776 1.258"
      ></path>
    </svg>

            <P style={{marginLeft: "20px"}} size="1em">Присоединиться к нашему чату</P>
</Button>
</Screen4></a>

        
        </Body>  
)
}