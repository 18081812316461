// Copyright 2021 andreygorbov
// 
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
// 
//     http://www.apache.org/licenses/LICENSE-2.0
// 
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React from 'react';
import styled from 'styled-components'
import { AiOutlinePlus, AiOutlineDelete, AiOutlineEdit} from "react-icons/ai";

const Wrap = styled.button`

width: ${({ width }) => width};
display: flex;
align-items: center;
margin: ${({ margin})=> margin?margin: '5px 0'} ;
background-color: ${({theme})=> theme.button.bg};
border: 1px solid transparent;
border-bottom-left-radius: 3.2px;
border-bottom-right-radius: 3.2px;
border-radius: 0.2rem;
border-top-left-radius: 3.2px;
border-top-right-radius: 3.2px;
box-sizing: border-box;
color: ${({theme})=> theme.button.color};
cursor: pointer;
font-family: 'Ubuntu';

font-weight: 400;
outline: none;
padding: ${({padding})=> padding?padding:'0.25rem 0.7rem'};
text-align: center;
justify-content: center;
text-decoration: none;
transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
user-select: none;

&:hover{
background-color: ${({theme})=> theme.button.hoverbg};
color: ${({theme})=> theme.button.color};
text-decoration: none;
}
&:focus{
background-color: ${({theme})=> theme.button.focusbg};
border-color: ${({theme})=> theme.button.focusbg};
color: ${({theme})=> theme.button.tfocus};
outline: 0px;
}
&:disabled{
background-color:  ${({theme})=> theme.button.disabledbg};
border-color: r${({theme})=> theme.button.disabledbg};
color: ${({theme})=> theme.button.tdisabled};
opacity: 0.65;
pointer-events: none;
}
&:activ{
background-color:  ${({theme})=> theme.button.activbg};
border-color: ${({theme})=> theme.button.activbg};
color: ${({theme})=> theme.button.tactiv};
}
`

const Text = styled.p`
margin:0;
font-size: 0.9em;
line-height: normal;
`

export const Button = ({ padding, size, margin, children, text, icon, iconsize, onClick, color, fillcolor, border, height, width, radius, disabled}) => {
    var myicon
    if (icon === "add") {
        myicon = <AiOutlinePlus size={iconsize} />
    } else if (icon === "del") {
        myicon = <AiOutlineDelete size={iconsize} />
    } else if (icon === "edit") {
        myicon = <AiOutlineEdit size={iconsize}/>
    } else {
        myicon = null
    }

    return (
        <Wrap disabled={disabled} onClick={onClick}
            fillcolor={fillcolor}
            border={border}
            color={color}
            width={width}
            height={height}
            radius={radius}
            margin={margin}
            padding={padding}
           
        >
        {myicon}
            {(text || children) && <Text  size={size}>{text || children}</Text>}
        </Wrap>
    )
}