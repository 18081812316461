// Copyright 2021 andreygorbov
// 
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
// 
//     http://www.apache.org/licenses/LICENSE-2.0
// 
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React, { useState, useContext,useEffect} from 'react';
import styled from 'styled-components'
import Resizer from "react-image-file-resizer";
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import DOMPurify from 'dompurify';
import { Text } from './ui/text'
import { Input } from './ui/input'
import { Button } from './ui/button'
import axios from 'axios'
import { Video } from './video'
import { Player } from './pleyer'
import { IMG } from './ui/img'
import { API_URL } from '../var'
import {StoreContext} from '../store/store'


const Wrapper = styled.div`
display: flex;
flex-grow:1;
flex-direction: column;
margin: ${({ open }) => open ? '2rem 0 0 2rem' : '2rem 0 0 0 0'};
padding: 2.5rem 2.5rem;
flex: 1;
direction: ltr;
`
const Pre = styled.div`
display:flex;
flex-direction:column;
`
const WrapEditor = styled.div`
display:flex;
box-sizing:border-box;
margin: 4em 1em;
padding:20px;
border: solid 2px ${({theme})=> theme.text};
`

const AddFilesWrap = styled.div`
display:flex;
flex-direction: column;
box-sizing:border-box;
`
const Div = styled.div`
display: flex;
flex-wrap: wrap;
flex-grow:1;
justify-content: space-evenly;
margin: 20px 0;
`
const Add = styled.div`
display: flex;
`

const UploadProcent = styled.p`

`

const ProgressAudio = () => {
  const [{ loadedAudio }] = useContext(StoreContext);
  
  return (<>
    { loadedAudio!== 0 ? <Text>{`Статус загрузки: ${ loadedAudio }%`}</Text > : <></>}
    </>)
}
const ProgressImage = () => {
  const [{ loadedImg }] = useContext(StoreContext);
  return (
    (<>
      { loadedImg!== 0 ? <Text>{`Статус загрузки: ${ loadedImg }%`}</Text > : <></>}
      </>)
  )
}

export const EditLesson = ({ open,  auth, fetchLesson}) => {
  const [{select,img, aud, lessonData, lessons, courseIndex, courses, lessonId }, dispatch] = useContext(StoreContext);

  let courseId = courses[courseIndex].id
  let lesson = lessons[lessonId]
  

 

  useEffect(() => {
   
    if (!lessonData.courseId) {
      dispatch({type: "SET_LESSON_DATA", payload: {...lessonData, courseId, idc:auth.idc}})
    }
  }, [auth.idc, courseId, dispatch, lessonData]);


    const [editorState, setEditorState] = useState(
      (lesson && lesson.text !== null) ?
        EditorState.createWithContent(convertFromRaw(lesson?.text)) : EditorState.createEmpty(),
    );
  
    
  


  const [convertedContent, setConvertedContent] = useState(null);
 

  

  
 

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
    const curr = convertToRaw(editorState.getCurrentContent())
    dispatch({type: "SET_LESSON_DATA", payload: {...lessonData, text: curr}})
      }

/* ------------------- Чтение данных редактора к переносу ------------------- */
  
  const convertContentToHTML = () => {
        let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);

      }
      
      const createMarkup = (html) => {
        return  {
          __html: DOMPurify.sanitize(html)
        }
      }

/* ------------------------------ resize image ------------------------------ */

  


  const resizeFile = (file) =>
  new Promise(() => {
    Resizer.imageFileResizer(
      file,
      1200,
      900,
      "JPEG",
      70,
      0,
      (uri) => {
        dispatch({type: 'SET_IMG', payload:uri })
      },
      "file"
    );
  });




  const addImage = async (event) => {
   
    try {
      
      const file = event.target.files[0];
     
      resizeFile(file);
      if (!lessonData.lessonId) {
        await updateLessonOnImageUpload()
       }
    } catch (err) {
      console.log(err);
    }
  };

  const sendLesson = async () => {
    
    await axios.post(API_URL + '/lessons', lessonData).then(async () => {
      await fetchLesson({ courseId })
      dispatch({ type: 'SET_SELECT', payload: NaN  })
      dispatch({ type: 'SET_NEWLESSON', payload: false })
      dispatch({ type: "CLEAR_LESSON_DATA"})
      
    }
    ).catch(e => console.error(e))
      //.finally(() => dispatch({ type: "SET_LESSON_ID", payload: lessons.length }))
  }
  
  const updateLessonOnImageUpload = () => {
    
    axios.post(API_URL + '/lessons', lessonData).then(async res => {
      await fetchLesson({ courseId }).then(() => {
        dispatch({ type: "SET_LESSON_DATA", payload: { ...lessonData, lessonId: res.data, action: 'edit' } })
     if (isNaN(select)) {
       dispatch({ type: 'SET_SELECT', payload: 0 })
     }
    dispatch({ type: 'SET_NEWLESSON', payload:false })
      })

     
   //  
   })
     //.finally(() => fetchLesson({ courseId }))
      .catch(e => console.error(e))
}

/* ---------------------------------- ==== ---------------------------------- */
/* -------------------------------- add audio ------------------------------- */
  const addAudio = async (e) => {
    const file = e.target.files[0]
    dispatch({ type: 'SET_AUDIO', payload: file })
    if (!lessonData.lessonId) { await updateLessonOnImageUpload() }
    
}

  
  const uploudAudio = async (file) => {
   
    var data = JSON.stringify({
      idc: auth.idc,
      lessonId: lessonData.lessonId,
      filetype: "audio",
      old: lesson && (lesson.audio && lesson.audio!=="")? lesson.audio.split('/')[lesson.audio.split('/').length - 1] : null
    });

    var formData = new FormData();
    formData.append("file", aud);
    formData.append('data', data);
    await axios.post(API_URL + '/upload', formData, {
      onUploadProgress: function(progressEvent) {
        dispatch({ type: 'SET_PROGRESS_AUDIO', payload: Math.round((progressEvent.loaded * 100) / progressEvent.total) })
      }
    }).then(async res => {
      if (res.data.upload === "ok") {
        dispatch({ type: "SET_LESSON_DATA", payload: { ...lessonData, audio: `/audios/${file.name}` } })
        dispatch({ type: 'SET_AUDIO', payload: null })
        dispatch({ type: 'SET_PROGRESS_AUDIO', payload: 0 })
     
        fetchLesson({ courseId })
      }
   })
}

  const uploudImg = async (file) => {
  var data = JSON.stringify({
    idc: auth.idc,
    lessonId: lessonData.lessonId,
    filetype: "image",
    old: (lesson && (lesson.image && lesson.image!=="")? lesson.image.split('/')[lesson?.image.split('/').length - 1] : null)
  });

  var formData = new FormData();
  formData.append("file", img);
  formData.append('data', data);
 await axios.post(API_URL+'/upload', formData, { onUploadProgress: function(progressEvent) {
  dispatch({ type: 'SET_PROGRESS_IMG', payload: Math.round((progressEvent.loaded * 100) / progressEvent.total) })
},
      headers: {
        'Content-Type': 'multipart/form-data'
      }
 }).then(async res => {
   if (res.data.upload === "ok") {
    dispatch({type: "SET_LESSON_DATA", payload: {...lessonData, image:`/images/${file.name}`}})
    dispatch({type: 'SET_IMG', payload: null})
    dispatch({ type: 'SET_PROGRESS_IMG', payload: 0 })

     fetchLesson({courseId})
   }
 })
}
  
  const delImg = async () => {
    var formData = new FormData();
    var data = JSON.stringify({
      idc: auth.idc,
      lessonId: lesson.id,
      filetype: "image",
      old: (lesson.image!==""? lesson.image.split('/')[lesson.image.split('/').length - 1] : null)
    });
    formData.append('data', data);
    await axios.post(API_URL + '/upload', formData).then(() => {
      dispatch({type: "SET_LESSON_DATA", payload: {...lessonData, image:""}})
      fetchLesson({courseId})
    }
   ).catch(e=>console.log(e))

}

const delAudio = async () => {
  var formData = new FormData();
  var data = JSON.stringify({
    idc: auth.idc,
    lessonId: lesson.id,
    filetype: "audio",
    old: (lesson.audio!==""? lesson.audio.split('/')[lesson.audio.split('/').length - 1] : null)
  });
  formData.append('data', data);
  await axios.post(API_URL + '/upload', formData).then(() => {
    dispatch({type: "SET_LESSON_DATA", payload: {...lessonData, audio:""}})
  fetchLesson({courseId})
  }).catch(e => console.log(e))
  
  
}
  
  
  /* --------------------------- отправка на сервер --------------------------- */


 



  return (
    <Wrapper open={open }>
      <Text type="h1">Созать урок</Text>
      <Input
        margin='1em'
        type='text'
        value={lessonData.name}
        placeholder="Введите название урока"
        onChange={e => dispatch({ type: "SET_LESSON_DATA", payload: { ...lessonData, name: e.target.value } })}
      />
      <Input type='text'
        margin='1em'
        value={lessonData&&lessonData.header}
        placeholder="Введите заголовок"
      onChange={e=> dispatch({ type: "SET_LESSON_DATA", payload: { ...lessonData, header: e.target.value } })}
      />
      
      <Input type='text'
        margin='1em'
        value={lessonData&&lessonData.video}
        placeholder="Введите код ролика Youtube"
      onChange={e=> dispatch({ type: "SET_LESSON_DATA", payload: { ...lessonData, video: e.target.value } })}
      />
      <Input type='number'
        margin='1em'
        value={lessonData && lessonData.queue}
        placeholder="Очередность"
        onChange={e => dispatch({ type: "SET_LESSON_DATA", payload: { ...lessonData, queue: e.target.value } }) }
      />
      {lessonData?.name  &&
        <>
      <WrapEditor>
        <Editor
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
          wrapperStyle={{ display: 'flex', flexDirection: 'column' }}
        />
      </WrapEditor>
      <AddFilesWrap>
       <UploadProcent>Добавить изображение</UploadProcent>
        <ProgressImage/>
        <Add>
            <Input type="file" margin="1em" ph={img?.name} name="image" onChange={addImage} accept="image/jpeg" />
            {img && <Button width="200px" margin="15px 0" onClick={() => uploudImg(img)}>Сохранить изображение</Button>}
          </Add>
          
      <UploadProcent>Добавить аудио</UploadProcent>
      <ProgressAudio/>
      <Add>
          <Input type="file" margin="1em" ph={aud?.name} name="audio" onChange={addAudio} accept="audio/ogg audio/mp3" />
       {aud && <Button width="200px" margin="15px 0" onClick={() => uploudAudio(aud)}>Сохранить аудио</Button>}
      </Add>
        {lessonData.image !=="" && <Button margin="1em" onClick={() => delImg()} width='300px'>Удалить изображение</Button>}
        {lessonData.audio !=="" && <Button margin="1em" onClick={() => delAudio()} width='300px'>Удалить аудио</Button>}
   
      </AddFilesWrap>
     
      <Text type="h2">Предпросмотр</Text>
      <Pre>
        <Text type="h1">{lessonData&&lessonData.header}</Text>
        {lessonData&&lessonData.image&& lessonData.image!=="" && <IMG src={`${API_URL}${lessonData&&lessonData.image}`}/>}
        {lessonData&&lessonData.video&& lessonData.video!=="" && <Video video={lessonData&&lessonData.video} width='100%' height='500px'/>}
        {lessonData&&lessonData.audio&& lessonData.audio!=="" && <Player audio={`${API_URL}${lessonData&&lessonData.audio}`} />}
      <div className="preview" dangerouslySetInnerHTML={createMarkup(convertedContent)}></div>
      </Pre>
<Div>
          <Button width='200px' size="1em" onClick={() => sendLesson()} text="Сохранить" />
          <Button width='200px' size="1em" onClick={() => {
          fetchLesson({courseId})
          dispatch({ type: 'SET_SELECT', payload: NaN})
            dispatch({ type: 'SET_NEWLESSON', payload: false })
            dispatch({ type: "CLEAR_LESSON_DATA"})
        }
        } text="Отменить" />
        </Div>
        </>}
        {!lessonData && <Button width='200px' size="1em" onClick={() => {
          fetchLesson({ courseId })
        dispatch({ type: 'SET_SELECT', payload: NaN  })
          dispatch({ type: 'SET_NEWLESSON', payload: false })
          dispatch({ type: "CLEAR_LESSON_DATA"})
        }
        } text="Отменить" />}
    </Wrapper>
)
}