// Copyright 2021 andreygorbov
// 
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
// 
//     http://www.apache.org/licenses/LICENSE-2.0
// 
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

export const dark = {
  size: '1em',
  bg: "#0a1d26",
  toggle: '#fff',
  heder: '#FBD784',
  text: '#fff',
  lessonbody: '#0d2733',
  leftmenu: '#133b4e',
  editortabbarbg: '#1b4e67',
  input: {
    placeholder: '#fff',
    border: '#0f3f56',
    disabled:'#808080',
    bg: '#266c8e',
    hover: '#133b4e',
    activ: '#24698a'
  },
  button: {
    bg: '#19475d',
    hoverbg: '#24698a',
    focusbg: '#24698a',
    disabledbg: '#808080',
    activbg:'#19475d',
    color: '#fff',
    hover: '#164358',
    focus: '#fff',
    activ: '#fff',
    disabled: '#fff',
    tfocus: '#fff',
    tactiv: '#fff',
    tdisabled: '#fff'
  },
  gradient: '180deg, rgba(11, 29, 38, 0) 0%, #0B1D26 50.38%',
  overley: {
    start: '0,0,0,.4',
    end: '0,0,0,.1'
  }
    
}

export const light = {
  size: '1em',
  bg: "#fff",
  toggle: '#41aee2',
  heder: '#d69706',
  text: '#35393c',
  lessonbody: '#fff',
  leftmenu:'#fff',
  input: {
    placeholder: '#35393c',
    border: '#0a1d26',
    disabled:'#808080',
    bg: '#ececec',
    hover: '#fff',
    activ: '#3a98c5',
  },
  button: {
    bg: '#41aee2',
    hoverbg: '#3a98c5',
    focusbg: '#3a98c5',
    disabledbg: '#808080',
    activbg:'#c1e1f1',
    color: '#fff',
    hover: '#c1e1f1',
    focus: '#fff',
    activ: '#fff',
    disabled: '#fff',
    tfocus: '#fff',
    tactiv: '#fff',
    tdisabled: '#fff'

  },
  gradient: '180deg, rgba(11, 29, 38, 0) 0%, #fff 50.38%',
  overley: {
    start: '255,255,255,.4',
    end: '255,255,255,.1'
  }

  
}

//color: ${({theme})=> theme.text};