// Copyright 2021 andreygorbov
// 
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
// 
//     http://www.apache.org/licenses/LICENSE-2.0
// 
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React from 'react';
import styled, {keyframes} from 'styled-components'

const rotate = keyframes`
0% {transform: rotate(0deg)}
100%{transform: rotate(360deg)}
`

const Wrapper = styled.div`
height: 100vh;
width: 100%;
display:flex;
justify-content:center;
align-items:center;
`
const Img = styled.svg`
width: 100px;
height: 100px;
#trigrams {
transform-origin: 50% 50%;
animation-name: ${rotate};
animation-duration: 5s;
animation-iteration-count: infinite;
animation-timing-function: linear;
}
`
export const Uploader = () => (
    <Wrapper>

        <Img
    xmlns="http://www.w3.org/2000/svg"
    
      fill="none"
      viewBox="0 0 395 396"
    >
      <g style={{ mixBlendMode: "multiply" }} stroke="#ECB869">
        <path d="M58.093 59.093H336.832V337.832H58.093z"></path>
        <path
          d="M0.72 200.737H279.02700000000004V479.044H0.72z"
          transform="rotate(-45.651 .72 200.737)"
        ></path>
        <g>
          <path d="M197.165 111.756c-39.556-7.35-68.353-12.165-95.567-42.579-22.712-29.4 3.797-49.929 3.797-49.929s15.19-2.28 26.582 29.147c11.392 54.237 65.188 63.361 65.188 63.361zm0 0c38.923-7.856 59.176-8.363 91.453-38.777 27.847-31.174 0-53.73 0-53.73s-19.936 2.787-24.999 27.118c-22.784 63.615-66.454 65.389-66.454 65.389z"></path>
          <path d="M197.165 111.756c-39.556-7.349-53.796-35.229-65.505-64.629C128.179 16.46 150.647 5.31 150.647 5.31s16.437 0 22.133 29.146c0 54.998 24.385 77.301 24.385 77.301zm0 0c38.923-7.856 56.96-32.694 65.188-64.629 0-31.934-19.62-41.818-19.62-41.818s-19.619 0-24.683 29.146c0 70.965-20.885 77.301-20.885 77.301z"></path>
          <g>
            <path d="M196.929 3.534c-13.291 18.756-13.924 21.037 0 34.216 14.995 14.193 0 21.796 0 21.796s-20.253 9.378 0 21.036c17.088 9.837 4.561 24.247-.397 30.161"></path>
            <path d="M196.454 3.534c13.29 18.756 13.923 21.037 0 34.216-14.995 14.193 0 21.796 0 21.796s20.252 9.378 0 21.036c-17.088 9.837-5.513 24.247-.555 30.161"></path>
          </g>
          <path d="M195.996 110.996c-17.042-24.922-40.9-81.813 0-109.996 39.55 26.358 16.077 106.955 0 109.996z"></path>
        </g>
        <g id="trigrams">
          <path d="M336.906 198.463c0 76.973-62.257 139.37-139.053 139.37-76.796 0-139.053-62.397-139.053-139.37 0-76.973 62.257-139.37 139.053-139.37 76.796 0 139.053 62.397 139.053 139.37z"></path>
          <circle cx="198.096" cy="198.463" r="88.738"></circle>
          <g fillRule="evenodd" clipRule="evenodd">
            <g>
              <path d="M86.68 173.78v48.909h-5.697V173.78h5.696z"></path>
              <g>
                <path d="M77.213 203.705v18.984h-5.696v-18.984h5.696z"></path>
                <path d="M77.213 173.78v18.985h-5.696V173.78h5.696z"></path>
              </g>
              <g>
                <path d="M95.751 203.705v18.984h-5.696v-18.984h5.696z"></path>
                <path d="M95.751 173.78v18.985h-5.696V173.78h5.696z"></path>
              </g>
            </g>
            <g>
              <path d="M307.055 268.408l-34.584 34.584-4.027-4.027 34.584-34.584 4.027 4.027z"></path>
              <path d="M300.64 261.994l-34.584 34.584-4.027-4.028 34.584-34.584 4.027 4.028z"></path>
              <g>
                <path d="M272.508 276.181l-13.424 13.424-4.028-4.028 13.424-13.424 4.028 4.028z"></path>
                <path d="M293.668 255.021l-13.424 13.424-4.028-4.028 13.424-13.424 4.028 4.028z"></path>
              </g>
            </g>
            <g>
              <path d="M124.455 304.612l-34.584-34.584L93.899 266l34.584 34.584-4.028 4.028z"></path>
              <g>
                <path d="M109.989 276.759l-13.425-13.424 4.028-4.028 13.424 13.424-4.027 4.028z"></path>
                <path d="M131.148 297.919l-13.424-13.425 4.028-4.027 13.424 13.424-4.028 4.028z"></path>
              </g>
              <g>
                <path d="M116.403 270.344l-13.424-13.424 4.028-4.028 13.424 13.424-4.028 4.028z"></path>
                <path d="M137.563 291.504l-13.424-13.424 4.028-4.028 13.424 13.424-4.028 4.028z"></path>
              </g>
            </g>
            <g>
              <path d="M272.825 92.77l34.584 34.584-4.027 4.028-34.584-34.585 4.027-4.027z"></path>
              <g>
                <path d="M287.292 120.623l13.424 13.424-4.028 4.028-13.424-13.424 4.028-4.028z"></path>
                <path d="M266.132 99.463l13.424 13.425-4.028 4.027-13.424-13.424 4.028-4.028z"></path>
              </g>
              <g>
                <path d="M280.877 127.038l13.424 13.424-4.028 4.028-13.424-13.424 4.028-4.028z"></path>
                <path d="M259.717 105.878l13.424 13.424-4.028 4.028-13.424-13.424 4.028-4.028z"></path>
              </g>
            </g>
            <g>
              <path d="M88.605 131.948l32.391-36.647 4.268 3.773-32.391 36.646-4.268-3.772z"></path>
              <path d="M95.402 137.956l32.391-36.647 4.268 3.773-32.39 36.646-4.269-3.772z"></path>
              <g>
                <path d="M122.609 122.064l12.572-14.224 4.268 3.772-12.572 14.225-4.268-3.773z"></path>
                <path d="M102.791 144.486l12.572-14.225 4.268 3.773-12.572 14.224-4.268-3.772z"></path>
              </g>
            </g>
            <g>
              <path d="M299.359 222.689V173.78h5.696v48.909h-5.696z"></path>
              <path d="M318.291 222.689V173.78h5.696v48.909h-5.696z"></path>
              <g>
                <path d="M308.431 192.765V173.78h5.696v18.985h-5.696z"></path>
                <path d="M308.431 222.689v-18.984h5.696v18.984h-5.696z"></path>
              </g>
            </g>
            <g>
              <path d="M220.818 86.175h-48.909V80.48h48.909v5.696z"></path>
              <path d="M220.423 76.315h-48.909v-5.697h48.909v5.697z"></path>
              <path d="M220.818 95.247h-48.909v-5.696h48.909v5.696z"></path>
            </g>
            <g>
              <g>
                <path d="M191.764 307.954H172.78v-5.696h18.984v5.696z"></path>
                <path d="M221.689 307.954h-18.984v-5.696h18.984v5.696z"></path>
              </g>
              <g>
                <path d="M191.764 317.026H172.78v-5.696h18.984v5.696z"></path>
                <path d="M221.689 317.026h-18.984v-5.696h18.984v5.696z"></path>
              </g>
              <g>
                <path d="M191.764 326.492H172.78v-5.696h18.984v5.696z"></path>
                <path d="M221.689 326.492h-18.984v-5.696h18.984v5.696z"></path>
              </g>
            </g>
          </g>
        </g>
        <circle cx="196.83" cy="198.463" r="23.55"></circle>
        <g>
          <path d="M163.185 203.425c21.429 60.58 111.266 9.701 87.688-69.672 50.662 49.499 30.345 114.215-6.584 136.694-31.346 19.082-106.709 6.659-81.104-67.022z"></path>
          <path d="M232.135 193.792c-21.014-60.458-109.826-9.429-86.734 69.795-49.9-49.322-29.692-114.009 6.819-136.548 30.992-19.132 105.366-6.9 79.915 66.753z"></path>
        </g>
      </g>
    </Img>

    </Wrapper>

) 