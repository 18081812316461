import React, { useState } from 'react';
import 'fontsource-roboto';
import {useTheme} from './useTheme';
import {dark} from './theme'
import {Theme} from './themewrap'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,

} from "react-router-dom";
import { Courses } from "./components/courses"
import { Login } from './components/login'
import {NoPage} from './components/404'


import { GlobalStyles } from './global';
import { Oshkole} from './sait/oshkole'
import { SelectTrip } from './sait/selecttrip'
import {Programs} from './sait/programms'
import { tripdate } from './sait/data/expeditions'
import { Trip} from './sait/trip'
import Store from './store/store';

const App = () => {

  const { themeLoaded} = useTheme();
  const [auth, setAuth] = useState()
  const [selectedTheme, setSelectedTheme] = useState(dark);
 
  
  
  


  const triprouts = tripdate.map((r, key) => (
    <Route key={Date.now() + `${key}`}
      path={r.link}>
      <Trip
        setSelectedTheme={setSelectedTheme}
        bigimg={r.bigimg}
        header={r.header}
        h1={r.h1}
        blocks={r.blocks}
        video={r.video}
        invite={r.invite}
      />
    </Route>
  ))

  
  return (
  <>
      {themeLoaded && <Theme selectedTheme={selectedTheme}>
        <GlobalStyles />
       
      <Router>
        <Switch>
   
          {triprouts}
        
          <Route exact path="/">
              {!auth?.idc ? <Login setAuth={setAuth} setSelectedTheme={setSelectedTheme}/> : <Redirect to="/courses" />}
          </Route>
          
          <Route path="/about">
            <Oshkole setSelectedTheme={setSelectedTheme} />
            </Route>
            
            <Route path="/programms">
            <Programs setSelectedTheme={setSelectedTheme} />
          </Route>
                   
          <Route path="/expedition">
            <SelectTrip tripdate={tripdate} />
          </Route>
          

          
            {auth?.idc &&
            <Store>
            <Route path="/courses">
              <Courses auth={auth} setSelectedTheme={setSelectedTheme}/>
            </Route>
            </Store>
          }
            <Route path="*">
              <NoPage/>
          </Route>
        </Switch>
     
      </Router>
      </Theme>}
    </>
    );
}

export default App;
