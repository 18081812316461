// Copyright 2021 andreygorbov
// 
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
// 
//     http://www.apache.org/licenses/LICENSE-2.0
// 
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React from 'react';
import styled from 'styled-components'

const Image = styled.div`
cursor: pointer;
width: ${({ width }) => width? width: "100%" };
height: ${({ height }) => height? height: '700px' };
background-image: url(${({ src }) => src && src});
background-repeat: no-repeat;
background-size:contain;
background-position: center;
margin: 2rem 0;
@media screen and (max-width: 768px){
    height: 500px;  
}
`

export const IMG = ({ src, width, height }) => {
    return <a href={src}><Image src={src} width={width} height={height } /></a>
}