// Copyright 2021 andreygorbov
// 
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
// 
//     http://www.apache.org/licenses/LICENSE-2.0
// 
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

const Reducer = (state, action) => {
    switch (action.type) {
        case 'SET_COURSES':
            return {
                ...state,
                courses: action.payload
            };
        case 'SET_COURSE_INDEX':
            return {
                ...state,
                courseIndex: action.payload,
                lessonId:0
            };
        case 'SET_LESSONS':
            return {
                ...state,
                lessons: action.payload
            };
        case 'SET_CORSE_EDITOR_SELECTOR':
            return {
                ...state,
                selector: action.payload
            };
        case 'SET_LESSON_ID':
            return {
                ...state,
                lessonId: action.payload
            };
        case 'SET_SELECT_TO_EDIT':
            const { key, lesson } = action.payload
            console.log(["lesson in state"],lesson)
            return {
                ...state,
                select: key,
                lessonData: {...state.lessonData, ...lesson, lessonId: lesson.id, action: "edit"}
            };
            case 'SET_SELECT':
                 
                return {
                    ...state,
                    select: action.payload,
                };
        case 'SET_NEWLESSON':
            const { newlesson, queue } = action.payload
        
            return {
                ...state,
                newlesson,
                lessonId:undefined,
                select: NaN,
                lessonData: { ...state.lessonData, queue }
            };
        case 'SET_LESSON_DATA':
            return {
                ...state,
                lessonData: action.payload
            };
        case 'SET_IMG':
            return {
                ...state,
                img: action.payload
            };
        case 'SET_AUDIO':
            return {
                ...state,
                aud: action.payload
            };
        case 'SET_PROGRESS_AUDIO':
            return {
                ...state,
                loadedAudio: action.payload
            };
        case 'SET_PROGRESS_IMG':
            return {
                ...state,
                loadedImg: action.payload
            };
        case 'EDIT_COURSE':
            return {
                ...state, 
                courses: state.courses.map(cours => {
                    if (cours.id === action.payload.id) {

                       return action.payload;
                    }
                    return cours;
                }),
                
        }
        case 'CLEAR_LESSON_DATA':
            return {
                ...state,
                loadedImg: 0,
                loadedAudio: 0,
                lessonData: {
                action: 'add',
                video: "",
                text: null,
                name: "",
                header: "",
                image: "",
                audio: '',
                queue: 0}
            };
        default:
            return state;
    }
};

export default Reducer;