// Copyright 2021 andreygorbov
// 
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
// 
//     http://www.apache.org/licenses/LICENSE-2.0
// 
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React from 'react';
import styled from 'styled-components'

const InputStyle = styled.input`
margin:${({margin})=> margin?margin:'5px'};
appearance: none;
background-color:${({theme})=> theme.input.bg};
background-clip: padding-box;
border: 0px;
border-bottom-left-radius: 3.2px;
border-radius: 0.2rem;
border-top-left-radius: 3.2px;
bottom: 0px;
box-shadow: none;
box-sizing: border-box;
color:  ${({theme})=> theme.text};
direction: ltr;
display: flex;
flex: 1 1 auto;
font-family:'Ubuntu';
font-weight: 400;
height: 2.5rem;
left: 0px;
line-height: 21.45px;
min-width: 300px;
outline: none;
padding: 0.35rem 0.75rem;
position: relative;
right: 0px;
top: 0px;
transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
width: 1%;
&:focus {
border: solid 0.5px ${({theme})=> theme.input.border};
background-color:${({theme})=> theme.input.hover};
box-shadow: none;
color:  ${({theme})=> theme.text};
outline: 0px;
z-index: 3;
}
&:disabled {
  background-color:${({theme})=> theme.input.disabled};
opacity: 1;
}
&::placeholder {
  color:${({theme})=> theme.input.placeholder};
opacity: 1;
}
`
const Label = styled.label`
flex:1;
position: relative;
    display: inline-block;
    cursor: pointer;
    height: 2.5rem;
    color:red;
    margin: ${({margin})=> margin && margin};
`
const Span = styled.span`
background-color:${({theme})=> theme.input.bg};
border: 0.075rem solid  ${({theme})=> theme.input.hover};
border-radius: 0.25rem;
box-shadow:  rgba(0, 0, 0, 0.05) 0px 0.2rem 0.4rem inset;
color:  ${({theme})=> theme.text};
height: 2.5rem;
left: 0px;
line-height: 1.5;
padding: 0.5rem 1rem;
position: absolute;
right: 0px;
top: 0px;
user-select: none;
&::after {
content: ${({ ph }) => ph ? `'${ph}'` : 'add file...'};
}
&::before{
background-color:${({theme})=> theme.input.bg};
border: 0.075rem solid ${({theme})=> theme.input.border};
border-radius: 0px 0.25rem 0.25rem 0px;
bottom: -0.075rem;
color:   ${({theme})=> theme.text};
content: "Browse";
display: block;
height: 2.5rem;
line-height: 1.5;
padding: 0.5rem 1rem;
position: absolute;
right: -0.075rem;
top: -0.075rem;
z-index: 6;
}
`

const CheckBoxWrapper = styled.div`
  position: relative;
`;
const CheckBoxLabel = styled.label`
margin:5px;
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 26px;
  border-radius: 15px;
  background: ${({theme})=> theme.input.bg};
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 3px;
    background: ${({theme})=> theme.toggle};
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;
const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  &:checked + ${CheckBoxLabel} {
    background: ${({theme})=> theme.input.after};
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;

export const Input = ({ id, ph, margin, name, type, value, children, placeholder, height, onChange, checked }) => {

    if (type === 'file') {
        return (
            <Label margin={margin}>
                <input onChange={onChange} type="file"  id="file" />
                <Span ph={ph}></Span>
            </Label>
    )
    } else if (type === 'checkbox') {
      return (<CheckBoxWrapper>
        <CheckBox id={id} type="checkbox" checked={checked} onChange={ onChange} />
        <CheckBoxLabel htmlFor={id}/>
      </CheckBoxWrapper>  )
    }
    else {
        return (
          <InputStyle name={name} type={type} margin={margin } height={height} placeholder={placeholder} value={value} onChange={onChange}>{children}</InputStyle>
        )
    }
}