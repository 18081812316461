import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';


import { setToLS } from './storage';

const Index = () => {
  
  setToLS('theme', "dark");
  return (
    <React.StrictMode>
      <App />
      </React.StrictMode>
  )
}

ReactDOM.render(
    <Index />,
  document.getElementById('root')

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
