// Copyright 2021 andreygorbov
// 
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
// 
//     http://www.apache.org/licenses/LICENSE-2.0
// 
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React from 'react';
import styled from 'styled-components'

const P = styled.p`
font-size: ${({ size})=>size?size:"1em" };
box-sizing: border-box;
color:  ${({theme})=> theme.text};
font-family: 'Ubuntu';
list-style: outside none none;
margin: ${({ margin }) => margin ? margin : "0 0 1rem 0"};
font-weight: ${({bold})=> bold?bold:200};
outline: none;
text-align: ${({align})=> align?align:'left'};
word-wrap: break-word;
white-space: pre-wrap;
`
const H1 = styled.h1`
line-height: normal;
box-sizing: border-box;
color: ${({theme})=> theme.text};
font-family: 'Lora';
font-weight: ${({bold})=> bold?bold:200};
margin: ${({ margin }) => margin ? margin : "0 0 1rem 0"};
text-align: ${({align})=> align?align:'left'};
outline: none;
`
const H2 = styled.h2`
line-height: normal;
box-sizing: border-box;
color:  ${({theme})=> theme.text};
font-family: 'Lora';
font-weight: ${({bold})=> bold?bold:200};
margin: ${({ margin }) => margin ? margin : "0 0 1rem 0"};
text-align: ${({align})=> align?align:'left'};
outline: none;
`


export const Text = ({ type, size, color, children, margin, bold, align }) => {

    if (type === 'h1') {
        return (<H1 size={size} bold={bold} color={color} align={ align}>{children}</H1>)
    } else if (type === 'h2') {
        return (<H2 size={size} bold={ bold} color={ color} align={ align}>{children}</H2>)
    } else {
        return (<P size={size} bold={bold} color={color} margin={ margin} align={ align}>{children}</P>)
    } 

}
