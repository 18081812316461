import React from 'react';
import styled, { keyframes} from 'styled-components'
import {
    Link
} from "react-router-dom";


const overanimation = keyframes`
from {background-color: rgba(${({theme})=> theme.overley.start});}
 to {background-color: rgba(${({theme})=> theme.overley.end});}
`

const hederanimation = keyframes`
 0% { font-size: 1.3em;}
 100% { font-size: 2em; }
`
const Body = styled.div`
margin-top: 1.4rem;
height: fit-content;

`
const Grid = styled.div`
max-width:900px;
margin: auto;
display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows:repeat(6, 23vh) ;
  grid-template-areas: 
    "b b a a"
    "d d a a"
    "c c e e"
    "c c h h"
    "i i g g"
    "k k j j"
    "l l j j";
  grid-gap: 1rem;
  box-sizing: border-box;
  padding: 2rem 0;
  @media screen and (max-width: 770px) {
    grid-template-columns: repeat(3, 1fr);
  grid-template-rows:repeat(7, 23vh) ;
  grid-template-areas: 
    "a a b"
    "a a b"
    "d c c"
    "e e h"
    "g f f"
    "i i j";
  }
  @media screen and (max-width: 450px) {
   display:flex;
   flex-direction: column;
  }
`

export const Item = styled.div`
width: 100%;
height: 100%;
position: relative;
display:flex;
justify-content: center;
align-items: center;
box-sizing: border-box;
background-image: url(${({ img }) => img && img});
background-position: center;
background-repeat:no-repeat;
background-size:cover;
grid-area:  ${({ area }) => area && area};
color: #fff;

@media screen and (max-width: 450px) {
    height: 200px;
  }

`
const Over = styled.div`
z-index: 1;
position: absolute;
width: 100%;
height: 100%;
background-color: rgba(0,0,0,.4);
${Item}:hover & {
animation-name: ${overanimation};
 animation-duration: 1s;
 animation-iteration-count: 1;
 animation-fill-mode: forwards;  
}
`
const P = styled.p`
font-size: 1.3em;
z-index: 2;
text-transform:uppercase;
${Item}:hover & {
animation-name: ${hederanimation};
animation-duration: 0.5s;
animation-iteration-count: 1;
animation-fill-mode: forwards; 
}

`

const TripBild = ({children, img}) => (
    <Item  img={img}><P>{children}</P><Over/></Item>
)



export const SelectTrip = ({ tripdate }) => {
 
    const trips = tripdate.map((d, key) => (
        <Link style={{ gridArea: d.area }} key={`${key}` + Date.now()} to={d.link}>
            <TripBild  img={d.smallimg}>{d.name}</TripBild>
            </Link>
    ))

               
        
    return (
        
<Body>
            <Grid>
             
    {trips}

            </Grid>
           
</Body>
)

}